import React from "react"
import { graphql } from "gatsby"
import { debounce, throttle, bindAll } from "lodash"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import close from "../assets/close.svg"
import { Player, Layout, Fade } from "../components"
import { pickQuality, isMobile } from "../../utils/pick-quality"

class PrintDetail extends React.Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef()
    this.videos = []
    bindAll(this, [
      "onMouseMove",
      "onMouseOverControls",
      "onMouseLeaveControls",
      "onSliderInit",
      "onBeforeChange",
      "onAfterChange",
    ])
    this.onMouseMove = throttle(this.onMouseMove, 600)
    this._hideControls = debounce(this._hideControls, 1400)

    this.state = {
      hovering: false,
      index: 1,
      controls: true,
    }

    this.settings = {
      dots: false,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    }

    this.diff = (nextState, state) => key => {
      return nextState[key] !== state[key]
    }
  }

  componentWillUnmount() {
    this.onMouseMove.cancel()
    this._hideControls.cancel()
  }

  _showControls() {
    this.setState({ controls: true })
    this._hideControls()
  }

  _hideControls() {
    if (!this.state.hovering) {
      this.setState({ controls: false })
    }
  }

  onMouseMove() {
    !isMobile() && this._showControls(true)
  }

  onMouseOverControls() {
    this.setState({ hovering: true })
  }

  onMouseLeaveControls() {
    this.setState({ hovering: false })
  }

  onClickControl(direction) {
    const controls = { prev: "slickPrev", next: "slickNext" }
    this.slider.current[controls[direction]]()
  }

  onSliderInit() {
    setTimeout(() => {
      const slide = this.slider.current.innerSlider.list.querySelector(
        ".slick-slide"
      )
      slide.focus()
    }, 100)
  }

  onBeforeChange() {
    // console.log('before change');
  }

  onAfterChange(index) {
    this.setState({ index: index + 1 })
    this.videos.forEach(video => video && video.pause())
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = false
    const diff = this.diff(nextState, this.state)
    if (diff("controls") || diff("index")) {
      shouldUpdate = true
    }
    return shouldUpdate
  }

  stateClassName() {
    let className = ""
    const { controls } = this.state
    if (controls) {
      className += " show-controls"
    }
    return className
  }

  setVideoRef = () => ref => {
    this.videos.push(ref)
  }

  render() {
    return (
      <Layout page={this} modifiers={["print-detail"]}>
        <div
          className={`print-detail ${this.stateClassName()}`}
          onMouseMove={this.onMouseMove}
        >
          <div className="print-detail__overlay">
            <div className="wrapper relative z-1">
              <div className="flex justify-between mt-40">
                <div className="p-16" />
                <div className="text-center">
                  <h1 className="font-head text-24 lg:text-28 uppercase tracking-wide leading-tight">
                    {this.props.data.print.title}
                  </h1>
                  <span className="font-main text-12 lg:text-13 uppercase leading-tight">
                    {this.props.data.print.subtitle}
                  </span>
                </div>
                <div className="print-detail__close-btn">
                  <Fade to="/print/">
                    <img src={close} alt="close" />
                  </Fade>
                </div>
              </div>
            </div>
            <div className="print-detail__controls mb-30 pb-10 md:mb-0">
              <div className="wrapper">
                <div
                  className="relative"
                  onMouseOver={this.onMouseOverControls}
                  onMouseLeave={this.onMouseLeaveControls}
                >
                  <div
                    className="print-detail__control print-detail__control--back"
                    onClick={() => {
                      this.onClickControl("prev")
                    }}
                  />
                  <div
                    className="print-detail__control print-detail__control--next"
                    onClick={() => {
                      this.onClickControl("next")
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="print-detail__count mb-30 md:mb-40 font-head text-18 md:text-24">
              <span>{this.state.index}</span>/
              <span>{this.props.data.print.slides.length}</span>
            </div>
          </div>

          <Slider
            className="print-detail__slider"
            {...this.settings}
            onInit={this.onSliderInit}
            beforeChange={this.onBeforeChange}
            afterChange={this.onAfterChange}
            ref={this.slider}
          >
            {this.props.data.print.slides.map((slide, index) => {
              if (!slide.mp4Url) {
                return (
                  <div className="print-detail__picture" key={slide.id}>
                    <img
                      srcSet={`${
                        slide.picture.url
                      }?auto=format&fit=min&w=600 600w, ${
                        slide.picture.url
                      }?auto=format&fit=min&w=900 900w, ${
                        slide.picture.url
                      }?auto=format&fit=min&w=1600 1600w`}
                      alt=""
                    />
                  </div>
                )
              } else {
                return (
                  <div className="print-detail__video" key={slide.id}>
                    <Player
                      className="absolute pin-t w-full h-full player--contain"
                      cover={`${slide.picture.url}?auto=format&fit=min&w=900`}
                      src={pickQuality(slide)}
                      hasExpand
                      autoPlay={false}
                      ref={this.setVideoRef()}
                    />
                  </div>
                )
              }
            })}
          </Slider>
        </div>
      </Layout>
    )
  }
}

export default PrintDetail

export const query = graphql`
  query PrintDetailQuery($slug: String!) {
    print: datoCmsSlideshow(slug: { eq: $slug }) {
      id
      title
      subtitle
      slides {
        id
        picture {
          url
        }
        mp4Url
        mp4UrlSd
      }
    }
  }
`
